<template>
  <div>
    <div class="super">
      <div class="holder">
        <div>
          <Header />
          <!-- First section -->
          <section class="about">
            <div class="about_holder">
              <div class="row">
                <div class="col-md-6 col-lg-6">
                  <div class="about__wrap">
                    <h6>ABOUT US</h6>
                    <h4>
                      We provide a seamless and convenient rental and facility
                      management experience.
                    </h4>
                    <p>
                      <b style="color:#0033ea">Shelta</b> is a PropTech Startup
                      that provides guaranteed rent for Landlords & flexible
                      (monthly, biannual, quarterly) rent payment for tenants.
                      Shelta takes away the friction between Landlord-Tenant
                      relationships through our rent financing solution. We
                      stand in as pressure absorbers and risk takers for
                      Landlords with our guaranteed rent, property management
                      and free litigation coverage services. While providing
                      Tenants with flexible rent payment, that helps them pay
                      their rent consistently and conveniently.
                      <!-- Too often, renting a home puts property owners and renters
                    in difficult situations regarding their relationship. Shelta
                    offers well-researched, thought out strategies to make an
                    otherwise difficult experience pleasurble for both parties. -->
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="map1">
                    <img src="../assets/images/aboutUS_.svg" alt="aboutus" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- First section -->
          <!-- Second section -->
          <div class="founder">
            <div class="col-md-6 m-auto">
              <p>
                <span class="open-quote"
                  ><img src="../assets/images/open-quote.svg" alt="qoute"
                /></span>
                Home is both our largest investment and most personal space.
                Property owners and renters deserve a cleaner, faster process.
                We aim to upgrade the entire journey from renting to managing
                real estate.
                <span class="close-quote"
                  ><img src="../assets/images/close-quote.svg" alt="qoute"
                /></span>
              </p>
              <h5>Austine Igwe</h5>
              <h6>Co-Founder and CEO</h6>
            </div>
          </div>
          <!-- Second section -->
          <!-- Third section -->
          <section class="team__access">
            <div class="row">
              <div class="col-md-6 col-lg-6">
                <div class="access__holder">
                  <h4>
                    We have the team with the necessary expertise to assist you.
                  </h4>
                  <p>
                    Whatever repairs or maintenance requirement can be
                    effectively handled by qualified and verified vendors.
                    Thereby ensuring you get the best service every time.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-6">
                <div class="access__img__holder">
                  <img src="../assets/images/team.svg" alt="team" />
                </div>
              </div>
            </div>
          </section>
          <!-- Third section -->

          <!-- Our mission -->
          <div class="mission">
            <div class="mission__header">
              <h4>Our Mission</h4>
              <p>
                To Win While Living: <br />
                We’re driven by the simple idea that combining technology with
                proven logistics will revolutionize the living experience and
                management of real estate in Nigeria. By Integrating the whole
                renting and facility management process in Shelta, we made the
                whole experience quick, easy, and convenient. So owners rent
                their homes faster and get better returns. And renters find and
                enjoy living in a home they love.
              </p>
              <div>
                <img src="../assets/images/mission.png" alt="mission" />
              </div>
            </div>
          </div>
          <!-- Our mission -->

          <!-- Core Values -->
          <div class="core">
            <div class="core__holder">
              <h3>Our core values</h3>
              <div class="core__wrap col-md-6">
                <div class="integrity d-none d-lg-block">
                  <img src="../assets/images/integrity.svg" alt="integrete" />
                  <p>Integrity</p>
                </div>
                <div class="respect d-none d-lg-block">
                  <img src="../assets/images/respect.svg" alt="respect" />
                  <p>Respect</p>
                </div>
                <div class="innovation d-none d-lg-block">
                  <img src="../assets/images/innovation.svg" alt="inovation" />
                  <p>Innovation</p>
                </div>
                <div class="responsibility d-none d-lg-block">
                  <img
                    src="../assets/images/responsibilty.svg"
                    alt="responsibility"
                  />
                  <p>Responsibility</p>
                </div>
                <p class="value__text">
                  At Shelta, we believe serving landlords and renters means
                  making their lives easier and more meaningful. We do so with
                  our technology and professional teams that power our
                  innovative business model designed to elevate the overall
                  experience. We are driven by the passion to serve and the
                  commitment to deliver, drawing on our Virtues to shape the way
                  we do our work.
                </p>
              </div>
            </div>
          </div>
          <!-- Core values -->

          <!-- Meet our team starts -->
          <div class="team__holder d-none">
            <h3>Meet Our Team</h3>
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-3 col-lg-3">
                  <div class="team__img">
                    <img
                      src="../assets/images/austine-team.svg"
                      alt="team_member"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-lg-3">
                  <div class="team__img">
                    <img
                      src="../assets/images/nsima-team.svg"
                      alt="team_member"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-lg-3">
                  <div class="team__img">
                    <img
                      src="../assets/images/billy-team.svg"
                      alt="team_member"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-lg-3">
                  <div class="team__img">
                    <img
                      src="../assets/images/ray-team.svg"
                      alt="team_member"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Meet our team ends -->

          <!-- Get in touch with us -->
          <div class="intouch">
            <div class="intouch__holder">
              <h4>Get in touch with us today</h4>
              <p>Get the best property rental and management deal</p>
              <div class="contactus__btn">
                <router-link to="/contactus">
                  <button class="btn">Contact us</button>
                </router-link>
              </div>
            </div>
          </div>
          <!-- Get in tuch with us -->
        </div>
      </div>
      <FooterMain />
    </div>
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
export default {
  name: "AboutUs",
  components: {
    Header,
    FooterMain
  },

  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}
.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  background: $secondary;
}

/** First section */
.about {
  // padding: 3rem 2rem;
  // margin-top: 7rem;
  // background: url("../assets/images/aboutusN.svg") no-repeat center center;
}
.about_holder {
  // background: linear-gradient(89.7deg, #315C7C 0.37%, #001E33 0.37%, rgba(224, 235, 238, 0) 99.48%);
  padding: 3rem 2rem;
  margin-top: 7rem;
}
.about__wrap {
  padding: 4rem 0;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 46px;
    line-height: 27px;
    text-transform: uppercase;
    color: $primary;
    text-align: start;
    padding-bottom: 2rem;
  }
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 45px;
    color: $black;
    text-align: start;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: $black;
    text-align: start;
  }
}
.span-seamless {
  font-weight: bold;
}

.map1 {
  img {
    width: 100%;
  }
}
/** First section */

/** Co founder */
.founder {
  background: rgba(242, 245, 254, 0.8);
  padding: 2rem;
  div {
    p {
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: $black2;
    }
    h5 {
      font-family: Gotham-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: $black2;
    }
    h6 {
      font-family: Gotham;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: $black2;
    }
  }
}
.open-quote {
  padding-right: 0.5rem;
  padding-bottom: 1rem;
  img {
    width: 3rem;
  }
}
.close-quote {
  padding-left: 0.5rem;
  img {
    width: 3rem;
  }
}
/** Co founder */

/** Access */
.access__holder {
  text-align: start;
  padding-top: 8rem;
  h4 {
    font-family: Gotham-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: $black;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: $black2;
  }
}
.team__access {
  padding: 3rem 2rem;
}
.access__img__holder {
  text-align: end;
  // img {
  //   width: 100%;
  // }
}
/** Access */

/** Our mission */
.mission {
  padding-top: 2rem;
  background: url("../assets/images/Nigeria.svg") no-repeat center center;
  background-blend-mode: color-burn;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.mission__header {
  padding: 2rem 4rem;
  background: rgba(242, 245, 254, 0.5);
  h4 {
    font-family: Gotham-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 27px;
    text-align: center;
    color: $black;
    padding-bottom: 1rem;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    color: $black2;
  }
  div {
    padding-top: 12rem;
    img {
      width: 80%;
    }
  }
}
/** Our mission */

/** Core values */
.core {
  background: $secondary;
  padding-top: 4rem;
  padding-bottom: 4rem;
  &__holder {
    .core__wrap {
      margin: auto;
    }
    h3 {
      font-family: Gotham-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: $black;
      padding-bottom: 3rem;
    }
  }
}
.value__text {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #222222;
  padding: 4rem;
}
.core__wrap {
  position: relative;
  .integrity {
    position: absolute;
    background: $secondary;
    box-shadow: 10px 10px 30px 5px rgba(0, 51, 234, 0.05);
    border-radius: 10px;
    width: 123px;
    height: 100px;
    left: 41%;
    bottom: 84%;
    padding: 16px;
    img {
      width: 38%;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
    p {
      font-family: Gotham-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: $black;
    }
  }
  .respect {
    img {
      width: 45%;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
    p {
      font-family: Gotham-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: $black;
    }
    position: absolute;
    background: $secondary;
    box-shadow: 10px 10px 30px 5px rgba(0, 51, 234, 0.05);
    border-radius: 10px;
    width: 123px;
    height: 100px;
    right: 88%;
    bottom: 34%;
    padding: 16px;
  }
  .innovation {
    position: absolute;
    background: $secondary;
    box-shadow: 10px 10px 30px 5px rgba(0, 51, 234, 0.05);
    border-radius: 10px;
    width: 123px;
    height: 100px;
    right: 41%;
    top: 84%;
    padding: 16px;
    img {
      width: 40%;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
    p {
      font-family: Gotham-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: $black;
    }
  }
  .responsibility {
    position: absolute;
    background: $secondary;
    box-shadow: 10px 10px 30px 5px rgba(0, 51, 234, 0.05);
    border-radius: 10px;
    width: 123px;
    height: 100px;
    left: 88%;
    bottom: 34%;
    padding: 16px;
    img {
      width: 36%;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
    p {
      font-family: Gotham-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: $black;
    }
  }
}
/** Core values */

/** Meet our team **/
.team__holder {
  padding: 3rem;
  h3 {
    font-family: Gotham-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 50px;
    text-align: center;
    align-items: center;
    color: $black;
    padding-bottom: 2rem;
  }
}
.team__img {
  img {
    border: 10px solid #f2f5fe;
    width: 100%;
    height: 277.64px;
    object-fit: cover;
  }
}
/** Meet our team **/

/** Get in touch with us base */
.intouch {
  padding: 6rem 4rem;
  margin-bottom: 4rem;
  background: linear-gradient(
      90deg,
      #0033ea 0.54%,
      rgba(186, 201, 255, 0.03) 101.89%
    ),
    url("../assets/images/aboutus-banner.svg") no-repeat;
  // background-blend-mode: color-burn;
  // background-color: rgba(255, 255, 255, 0.5);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: start;
  height: 400px;
}
.intouch__holder {
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 45px;
    color: $secondary;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 45px;
    color: $secondary;
  }
}
.contactus__btn {
  button {
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    width: 198px;
    height: 60px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 15px;
    text-align: center;
    color: $secondary;
  }
}
/** Get in touch with us */

/** Mobile responsiveness */
@media only screen and (max-width: 599px) {
  .about__wrap {
    h6 {
      font-size: 21px;
      text-align: left;
      // text-decoration: underline;
      padding-bottom: 0.5rem;
    }
    p {
      text-align: left;
      font-size: 18px;
    }
    h4 {
      font-size: 20px;
      text-align: left;
      line-height: 30px;
    }
  }
  .about_holder {
    padding: 1rem 1rem 0 1rem;
    margin-top: 3rem;
  }
  // .about {
  //   padding: 7rem 2rem;
  // }

  .access__holder {
    padding-top: 2rem;
  }
  .access__img__holder > img {
    width: 100%;
  }
  .open-quote img,
  .close-quote img {
    width: 2rem;
  }
  .value__text {
    padding: 1rem;
    text-align: left;
    font-size: 18px;
  }
  .founder {
    padding: 2rem 1rem;
  }
  .access__holder h4 {
    font-size: 21px;
    line-height: 34px;
  }
  .mission__header {
    padding: 2rem 1rem;
    h4 {
      font-size: 30px;
    }
    p {
      font-size: 20px;
      text-align: left;
    }
  }
  .mission__header {
    div {
      padding-top: 2rem;
    }
  }
  .core__holder {
    h3 {
      padding-top: 2rem;
      padding-bottom: 1rem;
    }
  }
  .team__img {
    padding-bottom: 1rem;
    img {
      height: unset;
    }
  }
  .intouch {
    margin-bottom: 6rem;
    height: fit-content;
    padding: 2rem 1rem;
  }
  .intouch__holder {
    h4 {
      font-size: 32px;
    }
    p {
      font-size: 18px;
    }
  }
  .contactus__btn {
    text-align: center;
    button {
      width: 152px;
      font-size: 15px;
    }
  }
  .founder {
    div {
      p {
        font-size: 16px;
        text-align: left;
      }
    }
  }
}
</style>
